@media screen and (max-width: 1280px) and (min-width: 750px) {
    body {
        & > section {
            width: 100%;
        }
    }

    .header {
        height: 43rem;
        
        .centered {
            width: 64rem;
            padding-left: 6rem;
            padding-top: 7.5rem;
        }
        .button_container{
            width: 64rem;
            padding-left: 6rem;
        }
    }

    .why_buzud_cgms {
        .block-container {
            height: 53.625rem;

            .block {
                padding: 1rem;

                h3 {
                    font-size: 1.25rem;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.005rem;
                }

                p {
                    font-size: 0.875rem;
                    line-height: normal;
                    letter-spacing: -0.0035rem;
                }
            }

            .calibration_free {
                width: 24rem;
                height: 11.625rem;
            }

            .no_scan_needed {
                width: 24rem;
                height: 11.625rem;
                left: 24.75rem;
            }

            ._14_days_wearing {
                width: 11.625rem;
                height: 11.625rem;
                left: 49.5rem;
            }

            .high_accuracy {
                width: 11.625rem;
                height: 24rem;
                top: 12.375rem;
            }

            .low_cost {
                width: 11.625rem;
                height: 11.625rem;
                left: 12.375rem;
                top: 12.375rem;
            }

            .little_pain_for_implanting {
                width: 11.625rem;
                height: 11.625rem;
                left: 24.75rem;
                top: 12.375rem;
            }

            .waterproof {
                width: 24rem;
                height: 11.625rem;
                left: 37.125rem;
                top: 12.375rem;
            }

            .smart {
                width: 24rem;
                height: 11.625rem;
                left: 12.375rem;
                top: 24.75rem;
            }

            .dual_certification {
                width: 11.625rem;
                height: 11.625rem;
                left: 37.125rem;
                top: 24.75rem;
            }

            .data_sharing {
                width: 11.625rem;
                height: 11.625rem;
                left: 49.5rem;
                top: 24.75rem;
            }

            .real-time_glucose_monitoring {
                width: 61.1rem;
                height: 19rem;
                top: 37.125rem;
                padding: 2.5rem 1.5rem 3rem;

                .content {
                    width: 34.0625rem;

                }
            }
        }
    }

    .intro_videos {
        flex-direction: column;
        height: 60.25rem;
    }

    .sensor_introduction {
        overflow: hidden;
        .description {
            width: 61rem;
        }

        .card_container {
            width: 100%;
            height: 31.125rem;
        }

        .features {
            display: flex;
            flex-direction: row;
            margin: 1.5rem auto 0;
            width: 61rem;

            .feature_list {
                margin-left: 2.5rem;
            }
        }
    }

    .apply_steps {

        .desc {
            width: 61rem;
        }

        .steps_container {
            margin: 2.5rem auto;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            height: 22.25rem;
            justify-content: center;


        }

        .apply_process {
            margin-top: 1.5rem;

            .img {
                display: block;
                width: 61rem;
                height: 28.46994rem;
            }

            .img_mobile {
                display: none;
            }
        }
    }


    .profile_reports {
        padding: 0 2.5rem;
        .content {
            width: 61rem;
            padding-top: 9.44rem;
            


            .img {
                display: block;
                width: 23.875rem;
                height: 21.125rem;
            }
        }

    }


    .how_works {
        width: 100%;
        padding:0;
        .content {
            width: 100%;

            .right {
                width: 35rem;
                right:2.5rem;
            }

            .img-container {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                display: flex;
                justify-content: center;
                overflow: hidden;

                .img {
                    display: block;
                    width: 80rem;
                    height: 29.29688rem;
                }
            }
        }
    }

    .packet-plans {
        height: 55.3rem;

        .content {
            width: 61rem;

           
            .guide-contact {

                .guide {
                    max-width: 28.125rem;
                }

            }

            .plans {
                gap: 1.56rem;

                .plan {
                    padding-top: 1.5rem;
                    width: 19.3125rem;
                    height: 30.875rem;
                    overflow: hidden;
                    text-align: center;
                    position: relative;

                    .buy {


                        .price {
                            font-size: 1.5rem;
                            line-height: 2rem; /* 133.333% */
                            letter-spacing: -0.006rem;
                        }

                        .gst {
                            font-size: 0.75rem;
                            line-height: 1rem; /* 133.333% */
                            letter-spacing: -0.003rem;
                        }

                        .buy-now {
                            padding: 0.5rem 0.9375rem;
                        }
                    }
                }
            }
        }
    }

    .doctor-registration {
        .doctor{
            display: none;
        }

        .content {
            width: fit-content;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            .desc {
                width:fit-content;
                max-width: 59rem;
            }
            
            .input-submit{
                text-align: center;
            }
        }
    }
}