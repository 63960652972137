h1, h2, h3, h4 {
    font-weight: normal
}

h5, h6 {
    font-weight: normal;
    font-size: 100%
}

body {
    font: normal 16px/1.5 "Barlow", "Roboto", "PingFang SC", Helvetica, "微软雅黑", sans-serif;
    background: #fff;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

.layui-btn-primary {
    padding: 1.25rem 1.5rem;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.005rem;
}

.layui-btn-primary:hover {
    border-color: var(--text-color-link, #00A9CE);
}

.layui-input {
    border-radius: 0.5rem;

    padding: 0.75rem 1rem;
    border: 0.0625rem solid var(--control-line, rgba(14, 15, 16, 0.16));
}

.layui-input:hover, .layui-input:focus {
    border-color: var(--text-color-link, #00A9CE) !important;
}

.layui-form-radio:hover>*, .layui-form-radioed, .layui-form-radioed>i {
    color: var(--text-color-link, #00A9CE) !important;
}

.layui-form-radio>*{
    font-size: 1.0625rem;
}

.layui-menu {
    li {
        padding: 1.125rem 1rem;
        height: 3.875rem;
        border-bottom: 0.0625rem solid var(--control-line, rgba(14, 15, 16, 0.16));

        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 141.176% */
        letter-spacing: -0.00425rem;

        &:last-of-type {
            border-bottom: none;
        }
    }
}


.layui-dropdown-menu {
    max-height: 23rem;
    overflow-y: scroll;
}

.layui-space {
    height: 0;
    margin: 0.5rem 0;
    border: none;
}

.layui-input-group {
    width: 100%;
    border-radius: 0.5rem;

    padding: 1.25rem 1.5rem;
    border: 0.0625rem solid var(--control-line, rgba(14, 15, 16, 0.16));

    transition: all .3s;
    -webkit-transition: all .3s;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.005rem;
    cursor: pointer;


    &:hover {
        border-color: var(--text-color-link, #00A9CE);
    }

    display: block;

    .layui-input-prefix {
        padding: 0;
        text-align: left;
        float: left;
        white-space: nowrap;
        margin-right: 1rem;
    }

    .layui-input {
        border: none;
        padding: 0;
        outline: none;
        box-shadow: none;
        text-align: right;
        height: auto;
        width: inherit;
        border-radius: 0;
    }
}

.layui-form-checkbox {
    &[lay-skin=primary] > i {
        width: 1.5rem;
        height: 1.5rem;
        border-color: var(--text-color-link, #00A9CE);

        &:before {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

.layui-form-checked[lay-skin=primary] > i {
    background-color: var(--text-color-link, #00A9CE);
}

.layui-btn-fluid {
    display: inline !important;
    text-align: center;
    background: var(--text-color-link, #00A9CE);
}

.layui-laydate {
    border-radius: 0.5rem;
    box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.09), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
    border: 0.0625rem solid var(--control-line, rgba(14, 15, 16, 0.16));

    .layui-laydate-main {
        width: 27.75rem;

        .layui-laydate-header {
            padding: 1.25rem 1.5rem;
            border-bottom: none;
            
            i{
                top: 1.5rem;
                padding: 0 0.5rem;
                color: #0E0F10;
                font-size: 1rem;
                cursor: pointer;
            
            }

            .laydate-prev-y, .laydate-next-y {
                display: none;
            }

            .laydate-prev-m, .laydate-next-m {
                display: none;
            }

            .laydate-set-ym {
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.005rem;
                color: var(--text-color-text-primary, #0E0F10);

                display: flex;
                flex-direction: row-reverse;
                gap: 0.5rem;
                justify-content: center;

                span {
                    padding: 0.5rem 2.94rem;
                    min-width: 8rem;

                    border-radius: 0.25rem;
                    background: var(--surface-surface-dim, #F1F4F6);
                    position: relative;
                    display: block;

                    &::after {
                        content: "";
                        display: block;
                        min-width: 0.75rem;
                        height: 0.75rem;
                        right: 0.87rem;
                        top: 1rem;
                        position: absolute;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.82872 9.03586C5.42921 9.78647 6.57084 9.78647 7.17132 9.03586L11.0252 4.21851C11.4181 3.72744 11.0684 2.99999 10.4396 2.99999H1.56049C0.93161 2.99999 0.58198 3.72744 0.974838 4.21851L4.82872 9.03586Z' fill='%230E0F10'/%3E%3C/svg%3E");
                    }

                    &:hover {
                        color: var(--text-color-text-primary, #0E0F10);
                    }
                }
            }
        }

        .layui-laydate-content {
            padding: 0 0 1rem;

            table {
                width: 100%;
                border-collapse: collapse;

                td, th {
                    width: auto;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.0035rem;
                    color: var(--txt-color, #0E0F10);

                    & > div:hover {
                        color: var(--txt-color, #0E0F10);
                    }
                }

                thead {

                }

                th {
                    border: #fff solid 0.0625rem;
                    padding: 0.1875rem 1.1875rem 0.25rem 1.1875rem;
                    background: var(--surface-surface-dim, #F1F4F6);

                }

                td {
                    padding: 0;

                    & > div {
                        font-size: 1.0625rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.5rem; /* 141.176% */
                        letter-spacing: -0.00425rem;
                        height: 2.5rem;
                        padding: 0.5rem 0;
                        border-radius: 0.25rem !important;
                    }
                }

                .layui-this {
                    background: inherit !important;
                }

                .layui-this > div {
                    background: var(--text-color-link, #00A9CE) !important;
                }

                .laydate-day-next, .laydate-day-prev {
                    color: #d2d2d2;
                }
            }

            .laydate-month-list {
                margin: 0;
                padding: 0;

                li {
                    padding: 1.25rem 0;
                    margin: 0;
                    font-size: 1.0625rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                    height: auto;
                    border-radius: 0.25rem;
                    width: 6.875rem;
                }

                .layui-this {
                    background: var(--text-color-link, #00A9CE) !important;
                }
            }

            .laydate-year-list {
                padding: 0;

                li {
                    width: 5.5rem;
                    height: auto;
                    padding: 1.25rem 0;
                    font-size: 1.0625rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                    border-radius: 0.25rem;
                }

                .layui-this {
                    background: var(--text-color-link, #00A9CE) !important;
                }
            }
        }
    }

    .laydate-ym-show {
        .layui-laydate-header {

            .laydate-prev-y, .laydate-next-y {
                display: none !important;
            }

            .laydate-prev-m, .laydate-next-m {
                display: none !important;;
            }

            .laydate-set-ym {
                span {
                    background: var(--text-color-link, #00A9CE);
                    color: var(--text-color-white-text, #FFF);

                    &::after {
                        content: "";
                        display: block;
                        min-width: 0.75rem;
                        height: 0.75rem;
                        right: 0.87rem;
                        top: 1rem;
                        position: absolute;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.82872 2.96414C5.42921 2.21353 6.57084 2.21353 7.17132 2.96414L11.0252 7.78149C11.4181 8.27256 11.0684 9.00001 10.4396 9.00001H1.56049C0.93161 9.00001 0.58198 8.27256 0.974838 7.78149L4.82872 2.96414Z' fill='white'/%3E%3C/svg%3E");
                    }

                    &:hover {
                        color: var(--text-color-white-text, #FFF);
                    }
                }
            }
        }


    }

    .laydate-ym-show:has(.laydate-year-list) {
        .layui-laydate-header {
            .laydate-prev-y, .laydate-next-y {
                display: block !important;
            }

        }
    }

    .layui-laydate-footer {
        display: none;
    }
}