@charset "utf-8";
@import "~@styles/common/variables.less";


.header {
    height: 37.75rem;
    background: url('@{image_root}/web/cgms/top_bg.jpg') no-repeat center;
    background-size: auto 100%;

    .centered {
        width: 80rem;
        margin: 0 auto;
        padding-left: 4.5625rem;
        padding-top: 7.5rem;
    }

    .button_container {
        width: 80rem;
        margin: 2.5rem auto 0;
        padding-left: 4.5625rem;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;

        .buy_btn {
            border: none;
            border-radius: 0.5rem;
            background: linear-gradient(180deg, #00CCB4 0%, #02A895 100%);
            box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

            padding: 0.75rem 3.125rem;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 177.778% */
            color: #fff;
            cursor: pointer;
        }

        .register_btn ,.download_brochure_btn{
            border: none;
            border-radius: 0.5rem;
            background: linear-gradient(180deg, #0081DE 0%, #0067B2 100%);
            box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

            padding: 0.75rem 1rem;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 177.778% */
            color: #fff;
            cursor: pointer;
            
            text-transform: uppercase;

        }
        
    }
}


body > section {
    width: 80rem;
    padding: 1.5rem;
    margin: 0 auto;
}

.page_desc {
    .top_bar {
        display: block;
        width: 4rem;
        height: 0.5rem;
        background: #00CCB4;
        margin-bottom: 0.75rem;
    }

    .desc {
        font-size: 1rem;
        line-height: 148%;
    }
}

.why_buzud_cgms {
    padding: 2.5rem 1.5625rem;
    overflow: hidden;

    .title {
        color: #00A9CE;
        font-size: 3rem;
        font-weight: 500;
        letter-spacing: -0.012rem;
        text-align: center;
    }

    .block-container {
        margin-top: 0.75rem;
        position: relative;
        height: 65.6875rem;

        .block {
            position: absolute;
            background: #F3F6F7;
            color: var(--txt-color-light, #0E0F10);
            padding: 1.5rem;

            h3 {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 2rem; /* 133.333% */
                letter-spacing: -0.006rem;
            }

            p {
                margin-top: 0.25rem;
                font-size: 1.0625rem;
                line-height: 1.5rem; /* 141.176% */
                letter-spacing: -0.00425rem;
            }
        }

        .calibration_free {
            width: 30.25rem;
            height: 14.625rem;
            left: 0;
            top: 0;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/calibration_free.png') no-repeat;
            background-size: 100% 100%;
        }

        .no_scan_needed {
            width: 30.1875rem;
            height: 14.625rem;
            left: 31.125rem;
            top: 0;

            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/no_scan_needed.png') no-repeat;
            background-size: 100% 100%;
        }

        ._14_days_wearing {
            width: 14.625rem;
            height: 14.625rem;
            left: 62.25rem;
            top: 0;

            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/_14_days_wearing.png') no-repeat;
            background-size: 100% 100%;
        }

        .high_accuracy {
            width: 14.625rem;
            height: 30.1875rem;
            left: 0;
            top: 15.5625rem;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/high_accuracy.png') no-repeat;
            background-size: 100% 100%;
        }

        .low_cost {
            width: 14.625rem;
            height: 14.625rem;
            left: 15.5625rem;
            top: 15.5625rem;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/low_cost.png') no-repeat;
            background-size: 100% 100%;
        }

        .little_pain_for_implanting {
            width: 14.625rem;
            height: 14.625rem;
            left: 31.125rem;
            top: 15.5625rem;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/little_pain_for_implanting.png') no-repeat;
            background-size: 100% 100%;
        }

        .waterproof {
            width: 30.1875rem;
            height: 14.625rem;
            left: 46.6875rem;
            top: 15.5625rem;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/waterproof.png') no-repeat;
            background-size: 100% 100%;
        }

        .smart {
            width: 30.1875rem;
            height: 14.625rem;
            left: 15.5625rem;
            top: 31.125rem;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/smart.png') no-repeat;
            background-size: 100% 100%;
        }

        .dual_certification {
            width: 14.625rem;
            height: 14.625rem;
            left: 46.6875rem;
            top: 31.125rem;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/dual_certification.png') no-repeat;
            background-size: 100% 100%;
        }

        .data_sharing {
            width: 14.625rem;
            height: 14.625rem;
            left: 62.3125rem;
            top: 31.125rem;
            background: url('@{image_root}/web/cgms/index/why_buzud_cgms/data_sharing.png') no-repeat;
            background-size: 100% 100%;
        }

        .real-time_glucose_monitoring {
            width: 76.875rem;
            height: 19rem;
            left: 0;
            top: 46.6875rem;
            padding: 2rem 3rem;

            .content {
                width: 40rem;

                .place_holder {
                    height: 1.5rem;
                }
            }

            .all_devices {
                position: absolute;
                bottom: 0;
                right: 1.5rem;
                display: block;
                width: 21.0625rem;
                height: 16.5rem;
            }
        }
    }


}

.intro_videos {
    width: 100%;
    height: 33.125rem;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    background: url('@{image_root}/web/cgms/index/video_bg.png') no-repeat bottom center;
    background-size: auto 28.125rem;

    .video {
        display: flex;
        width: 50rem;
        height: 28.125rem;
        justify-content: center;
        align-items: center;
        border: 0.625rem solid #FFF;
        box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
        background: url('@{image_root}/web/cgms/index/video_cover_v2_1.png') no-repeat center;
        background-size: 100% 100%;

        iframe {
            width: 100%;
            height: 100%;
        }

        &.video-1 {
            background: url('@{image_root}/web/cgms/index/video_cover_v2_1.png') no-repeat center;
            background-size: 100% 100%;
        }

        &.video-2 {
            background: url('@{image_root}/web/cgms/index/video_cover_v2_2.png') no-repeat center;
            background-size: 100% 100%;
        }
    }
}


.sensor_introduction {
    width: 100%;
    padding: 2.5rem 0;
    background: #084999;
    color: #F1F0EF;

    .title {
        font-size: 3rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.012rem;
        text-align: center;
        margin-top: 5rem;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .description {
        font-size: 1rem;
        margin: 0 auto;
        padding: 1.5rem 0;
        width: 77rem;
    }

    .card_container {
        width: 80rem;
        height: 31.125rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        padding: 0 1.5rem;
        overflow: scroll;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;
        -ms-overflow-style: none; /* For Internet Explorer and Edge */
        scrollbar-width: none; /* For Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        .card {
            display: flex;
            width: 18.5rem;
            height: 31.125rem;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            background-color: var(--bg-color, #fff);
            color: var(--txt-color, #0E0F10);

            .card_img {
                display: block;
                width: 18.5rem;
                height: 14.125rem;
            }

            .title {
                width: 100%;
                font-size: 1.25rem;
                font-weight: 500;
                letter-spacing: -0.005rem;
                padding: 0 1rem;
                text-align: left;
                height: 3rem;
            }

            .desc {
                padding: 0 1rem;
                font-size: 1rem;
                line-height: 148%;
            }
        }
    }

    .features {
        display: flex;
        flex-direction: row;
        margin: 1.5rem auto 0;
        width: 76.875rem;


        .image {
            width: 18.875rem;
            height: 38.8125rem;

            .card_img {
                display: block;
                width: 18.875rem;
                height: 38.8125rem;
            }
        }

        .feature_list {
            margin-left: 5.375rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .feature {
                background: #07569C;
                padding: 1.5rem;

                .title {
                    font-size: 1.25rem;
                    font-weight: 500;
                    letter-spacing: -0.005rem;
                    text-align: left;
                }

                .desc {
                    margin-top: 1rem;
                    font-size: 1rem;
                    line-height: 148%; /* 1.48rem */
                }
            }
        }
    }
}

.apply_steps {
    padding: 2.5rem 0;

    .title {
        font-size: 3rem;
        font-weight: 500;
        letter-spacing: -0.012rem;
        text-align: center;
        margin-top: 2.5rem;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .desc {
        font-size: 1rem;
        line-height: 148%; /* 1.48rem */
        width: 77rem;
        margin: 0 auto;
    }

    .steps_container {
        margin: 2.5rem auto;
        display: flex;
        flex-direction: row;
        gap: 4.5rem;
        height: 22.25rem;
        justify-content: center;

        .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 20rem;
            height: 22.25rem;

            .img {
                display: block;
                width: 15rem;
                height: 15rem;
            }

            .title {
                margin-top: 1rem;
                font-size: 2rem;
                font-weight: 500;
                line-height: 2.75rem; /* 137.5% */
                letter-spacing: -0.008rem;
            }

            .desc {
                margin-top: 0.5rem;
                font-size: 1.0625rem;
                line-height: 1.5rem; /* 141.176% */
                letter-spacing: -0.00425rem;
                white-space: normal;
                word-wrap: break-word;
                width: 20rem;
                text-align: center;
            }
        }
    }

    .apply_process {
        margin-top: 1.5rem;

        .img {
            display: block;
            margin: 0 auto;
            width: 77rem;
            height: 35.9375rem;
        }

        .img_mobile {
            display: none;
        }
    }
}

.profile_reports {
    background-size: auto 100%;
    background: url('@{image_root}/web/cgms/index/report_bg.jpg') no-repeat center;
    height: 40rem;
    width: 100%;

    .content {
        width: 77rem;
        margin: 0 auto;
        padding-top: 5.625rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .left {
            width: 31.5rem;
            color: #0E0F10;

            .title {
                font-size: 3rem;
                font-weight: 500;
                letter-spacing: -0.012rem;
            }

            .desc {
                margin-top: 1rem;
                font-size: 1rem;
                line-height: 148%;
            }
        }

        .img {
            display: block;
            width: 32.5rem;
            height: 28.75rem;
        }
    }

}

.how_works {
    width: 100%;
    height: 40rem;
    padding: 0;
    background-color: #BAC6CE;

    .content {
        width: 80rem;
        height: 40rem;
        margin: 0 auto;
        position: relative;
        overflow: hidden;

        .right {
            width: 38rem;
            position: absolute;
            right: 0;
            top: 5rem;
            z-index: 2;

            display: flex;
            flex-direction: column;
            color: #0E0F10;

            .title {
                font-size: 3rem;
                font-weight: 500;
                letter-spacing: -0.012rem;
            }

            .desc {
                font-size: 1rem;
                line-height: 148%; /* 1.48rem */
            }

            .features {
                margin-top: 1.5rem;
                border-radius: 0.5rem;
                background: #084999;
                padding: 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                color: #F1F0EF;

                .feature {
                    display: flex;
                    flex-direction: row;
                    gap: 0.5rem;
                    align-items: flex-start;

                    .checked {
                        margin-top: 0.25rem;
                        width: 1rem;
                        height: 1rem;
                        background: url('@{image_root}/web/cgms/index/checked.svg') no-repeat center;
                        background-size: contain;
                    }

                    .desc {
                        width: 33.5rem;
                    }
                }
            }
        }

        .img-container {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;

            .img {
                display: block;
                width: 80rem;
            }
        }
    }
}


.packet-plans {
    background-size: auto 100%;
    background-color: #93bfdc;
    background: url('@{image_root}/web/cgms/index/package-plan-bg.jpg') no-repeat center;
    height: 50.75rem;
    width: 100%;
    padding: 0;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #0E0F10;
        width: 77rem;
        margin: 0 auto;

        .title {
            padding: 2.5rem 0;
            font-size: 3rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.012rem;
        }

        .guide-contact {
            border-radius: 1.5rem;
            width: 100%;
            background-color: #fff;
            padding: 1.5rem 2rem 1.75rem;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .guide {
                max-width: 38.3125rem;
            }

            .contact {
                padding-right: 4rem;

                a {
                    color: #0E0F10;
                }
            }

            h3 {
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem; /* 133.333% */
                letter-spacing: -0.006rem;
            }

            p {
                margin-top: 1rem;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem; /* 141.176% */
                letter-spacing: -0.00425rem;
            }
        }

        .plans {
            margin-top: 2.5rem;
            display: flex;
            flex-direction: row;
            gap: 2.5rem;

            .plan {
                background-color: #fff;
                border-radius: 1.5rem;
                padding-top: 1.5rem;
                width: 24rem;
                height: 27.9rem;
                overflow: hidden;
                text-align: center;
                position: relative;

                h3 {
                    padding: 0 1.5rem;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2.75rem; /* 137.5% */
                    letter-spacing: -0.008rem;
                    text-align: center;
                }

                .period {
                    padding: 0 1.5rem;
                    font-size: 3rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.012rem;
                    color: #00CCB4;
                }

                .replacements {
                    margin-top: 0.5rem;
                    padding: 0 1.5rem;
                    font-size: 1.0625rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                }

                .divider {
                    border-bottom: none;
                    border-color: rgba(14, 15, 16, 0.16);
                    margin: 1.5rem 1.5rem;
                }

                .desc {
                    padding: 0 1.5rem;
                    text-align: left;
                    font-size: 1.0625rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                }

                .buy {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 6rem;
                    background-color: #F1F4F6;

                    padding: 0 1.5rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .pricing {
                        text-align: left;
                    }

                    .price {
                        font-size: 2rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2.75rem; /* 137.5% */
                        letter-spacing: -0.008rem;
                    }

                    .gst {
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.0035rem;
                    }

                    .buy-now {
                        border: none;
                        border-radius: 0.5rem;
                        background: linear-gradient(180deg, #00CCB4 0%, #02A895 100%);
                        box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;
                        padding: 0.5rem 1.875rem;
                        text-shadow: 0 0.0625rem 0.1875rem #079685;
                        font-size: 1rem;
                        font-weight: 700;
                        line-height: 2rem; /* 200% */
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.doctor-registration {
    background-size: auto 100%;
    background-color: #93bfdc;
    background: url('@{image_root}/web/cgms/index/doc_reg.jpg') no-repeat center;
    width: 100%;
    padding: 0;

    position: relative;
    padding-bottom: 2.5rem;

    .doctor {
        position: absolute;
        bottom: 0;
        left: 50%;
        overflow: hidden;

        .img {
            display: block;
            width: 50vw;
        }
    }

    .content {
        width: 77rem;
        margin: 0 auto;
        color: #0E0F10;


        .title {
            padding-top: 2.5rem;
            font-size: 3rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.012rem;

            text-align: center;
        }

        .desc {
            margin-top: 1rem;
            width: 35rem;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 148%; /* 1.48rem */
        }

        .form {
            margin-top: 2.5rem;
            width: 35rem;

            .input {
                .error-message {
                    display: none;
                }

                &.error {
                    input {
                        border-color: #f55;
                    }

                    .error-message {
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -1.5rem;
                        font-size: 1.0625rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.5rem; /* 141.176% */
                        letter-spacing: -0.00425rem;
                        color: #f55;
                    }
                }
            }

            .input-submit {
                margin-top: 1.5rem;

                .error-message {
                    display: none;
                }

                &.error {
                    input {
                        border-color: #f55;
                    }

                    .error-message {
                        display: block;
                        font-size: 1.0625rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.5rem; /* 141.176% */
                        letter-spacing: -0.00425rem;
                        color: #f55;
                    }
                }
            }

            .submit {
                border: none;
                border-radius: 0.5rem;
                background: linear-gradient(180deg, #0081DE 0%, #0067B2 100%);
                box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;
                padding: 0.75rem 1rem;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 177.778% */
                color: #fff;
                cursor: pointer;
                text-transform: uppercase;

            }
        }
    }

}

.manual_download {
    width: 100%;
    padding: 2.5rem;

    .title {
        text-align: center;
        font-size: 3rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.012rem;
    }

    .product_list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 3.5rem auto 0;
        gap: 2.5rem;

        .product {
            width: 37.25rem;
            height: 29.25rem;
            border-radius: 0.75rem;
            border: 0.0625rem solid #084999;
            padding: 1.5rem;
            
            h2{
                text-align: center;
                font-size: 2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2.75rem; /* 137.5% */
                letter-spacing: -0.008rem;
            }
            
            p{
                margin: 1.5rem auto 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                
                img{
                    display: block;
                    height: 15rem;
                }
            }
            
            .download_btn{
                margin: 2.5rem auto 0;
                width: 21.25rem;
                padding: 0.75rem 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 0.5rem;
                background: linear-gradient(180deg, #0081DE 0%, #0067B2 100%);
                box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 177.778% */
                text-transform: uppercase;
                
                cursor: pointer;
                color: #fff;
            }
        }
    }
}

.control_diabetes {
    width: 100%;
    padding: 2.5rem;
    background: #084999;
    color: #F1F0EF;
    text-align: center;

    .title {
        width: 50rem;
        margin: 0 auto;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: -0.008rem;
    }

    .desc {
        width: 50rem;
        margin: 1.5rem auto;
        font-size: 1rem;
        line-height: 148%;
    }
}

.pop {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .content {
        position: relative;
        background-color: #fefefe;
        margin: 15vh auto;
        padding: 1.5rem;
        border: none;
        width: 29.5rem;
        max-width: 100%;
        border-radius: 1rem;
        z-index: 200;
        max-height: 70vh;
        overflow-y: auto;
    }
}

.register-success {
    text-align: center;

    .title {
        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.75rem; /* 137.5% */
        letter-spacing: -0.008rem;
        color: #00CCB4;

    }

    .desc {
        margin-top: 0.75rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 148%; /* 1.48rem */
        color: #0E0F10;
        text-align: left;

    }

    .confirm {
        margin: 1.5rem auto 0;

        .confirm-btn {
            min-width: 15.75rem;;
            padding: 0.5rem 0;
            border-radius: 0.5rem;
            border: none;
            background: linear-gradient(180deg, #00CCB4 0%, #02A895 100%);
            box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 200% */

            color: #FFF;
            text-align: center;
            text-shadow: 0 0.0625rem 0.1875rem #079685;

            cursor: pointer;
        }
    ;
    }
}

.layui-input-group {
    border: none;
    width: 100%;
    border-radius: 0.5rem;

    margin-top: 1.5rem;
    padding: 0;

    transition: none;
    -webkit-transition: none;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 141.176% */
    letter-spacing: -0.00425rem;
    cursor: inherit;


    .layui-input-prefix {
        padding-bottom: 0.5rem;
        display: block;
        width: 100%;
    }

    .layui-input {
        border: 1px solid rgba(14, 15, 16, 0.16);
        border-radius: 0.5rem;

        padding: 0.75rem 1rem;
        outline: none;
        box-shadow: none;
        text-align: left;
        height: auto;
    }

    .layui-form-selected dl {
        margin-top: 0.375rem;
        margin-bottom: 0.375rem;
        border-radius: 0.5rem;

        dd.layui-this {
            color: #0E0F10;
        }
    }
}


@import './index_tablet.less';
@import './index_mobile.less';