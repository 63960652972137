@media screen and (max-width: 750px) {
    body {
        & > section {
            width: 100%;
        }
    }

    .header {
        height: 45.25rem;
        background: url('@{image_root}/web/cgms/top_bg_mobile.jpg') no-repeat center;
        background-size: auto 100%;

        .background {
            height: 45.25rem;
            overflow: hidden;
        }

        .centered {
            padding-left: 2.5rem;
            padding-top: 2.5rem;

            img {
                width: 18.4375rem;
                height: 5.125rem;
            }
        }

        .button_container{
            width: 18.4375rem;
            padding-left: 0;
            flex-direction: column;
            gap: 1rem;

            .buy_btn,.register_btn,.download_brochure_btn{
                width: 100%;
                
                padding: 0.25rem 0;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 200% */
            }
        }
    }

    .why_buzud_cgms {
        padding: 1.5rem 0.75rem;

        .title {
            font-size: 2rem;
            line-height: 2.75rem;
            letter-spacing: -0.008rem;
        }

        .block-container {
            margin-top: 1.5rem;
            height: 122rem;

            .block {
                padding: 0.75rem;

                h3 {
                    font-size: 1.0625rem;
                    line-height: 1.5rem;
                    letter-spacing: -0.00425rem;
                }

                p {
                    font-size: 0.875rem;
                    line-height: normal;
                    letter-spacing: -0.0035rem;
                }
            }

            .calibration_free {
                width: 10.625rem;
                height: 21.9375rem;
                background: url('@{image_root}/web/cgms/index/why_buzud_cgms/calibration_free_mobile.png') no-repeat;
                background-size: 100% 100%;
            }

            .low_cost {
                width: 10.625rem;
                height: 10.625rem;
                left: 11.315rem;
                top: 0;
            }

            .high_accuracy {
                width: 10.625rem;
                height: 21.9375rem;
                left: 11.315rem;
                top: 11.315rem;
            }


            .little_pain_for_implanting {
                width: 10.625rem;
                height: 10.625rem;
                left: 0;
                top: 22.63rem;
            }

            .waterproof {
                width: 10.625rem;
                height: 21.9375rem;
                left: 0;
                top: 33.945rem;
                background: url('@{image_root}/web/cgms/index/why_buzud_cgms/waterproof_mobile.png') no-repeat;
                background-size: 100% 100%;
            }

            ._14_days_wearing {
                width: 10.625rem;
                height: 10.625rem;
                left: 11.315rem;
                top: 33.945rem;
            }

            .smart {
                width: 10.625rem;
                height: 10.625rem;
                left: 11.315rem;
                top: 45.26rem;
            }

            .dual_certification {
                width: 10.625rem;
                height: 10.625rem;
                left: 0;
                top: 56.575rem;
            }

            .data_sharing {
                width: 10.625rem;
                height: 10.625rem;
                left: 11.315rem;
                top: 56.575rem;
            }

            .no_scan_needed {
                width: 21.9375rem;
                height: 10.625rem;
                left: 0;
                top: 67.89rem;
            }


            .real-time_glucose_monitoring {
                width: 21.9375rem;
                height: 42.75rem;
                top: 79.205rem;
                padding: 1rem 1rem;

                .content {
                    width: 19.9375rem;

                    h3 {
                        font-size: 1.25rem;
                        font-weight: 500;
                        letter-spacing: -0.005rem;
                    }

                    p {
                        font-size: 1.0625rem;
                        line-height: 1.5rem;
                        letter-spacing: -0.00425rem;
                    }

                }

                .all_devices {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 21.9375rem;
                    height: 17.1875rem;
                }
            }
        }
    }

    .intro_videos {
        flex-direction: column;
        padding: 1.5rem 3rem;
        height: 24.3rem;
        background: none;
        gap: 1rem;

        .video {
            width: 17.5rem;
            height: 9.875rem;
            border: 0.25rem solid #FFF;
            box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.25);
        }
    }

    .sensor_introduction {
        overflow: hidden;
        padding: 1.5rem 0 2.5rem;

        .title {
            font-size: 2rem;
            font-weight: 500;
            line-height: 2.75rem;
            letter-spacing: -0.008rem;
            margin-top: 3rem;
        }

        .description {
            width: 21.9375rem;
            padding: 1rem 0;
        }

        .card_container {
            width: 100%;
            height: auto;
            flex-direction: column;
            overflow: hidden;

            .card {
                display: flex;
                width: 21.9rem;
                height: 28.1rem;

                .card_img {
                    width: 21.9375rem;
                    height: 14.125rem;
                }

                .title {
                    line-height: normal;
                    height: auto;
                }

                &:last-of-type {
                    height: 23.6rem;
                }
            }
        }

        .features {
            display: flex;
            flex-direction: column;
            margin: 1.5rem auto 0;
            width: 100%;

            .image {
                margin: 0 auto;
                width: 9.875rem;
                height: 20.375rem;

                .card_img {
                    display: block;
                    width: 9.875rem;
                    height: 20.375rem;
                }
            }


            .feature_list {
                margin-left: 0;
                margin-top: 1.5rem;
                padding: 0 0.75rem;
                display: flex;
                flex-direction: column;

                .feature {
                    padding: 1rem;


                }
            }
        }
    }

    .apply_steps {
        padding: 1.5rem 0;

        .title {
            font-size: 2rem;
            font-weight: 500;
            line-height: 2.75rem;
            letter-spacing: -0.008rem;
            text-align: center;
            margin-top: 1.5rem;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .desc {
            padding: 1rem 0;
            width: 21.9375rem;
        }

        .steps_container {
            margin: 1rem auto;
            flex-direction: column;
            gap: 1.5rem;
            height: auto;
            justify-content: center;
            align-items: center;

            .step {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 15rem;
                height: auto;

                .desc {
                    width: 100%;
                }
            }
        }

        .apply_process {
            padding: 0 0.75rem;

            .img {
                display: none;
            }

            .img_mobile {
                display: block;
                width: 100%;
                min-height: 90.875rem;
            }
        }
    }


    .profile_reports {
        height: 39.4375rem;
        padding: 0 0.75rem;
        background-position: center bottom;
        background-size: auto 100%;

        .content {
            width: 100%;
            padding-top: 1.5rem;

            flex-direction: column;

            .left {
                width: 100%;

                .title {
                    font-size: 2rem;
                    font-weight: 500;
                    line-height: 2.75rem; /* 137.5% */
                    letter-spacing: -0.008rem;
                    text-align: center;
                }
            }

            .img {
                margin-top: 1.5rem;
                display: block;
                width: 21.9375rem;
                height: 19.4375rem;
            }
        }

    }


    .how_works {
        width: 100%;
        height: auto;
        position: static;

        .content {
            width: 100%;
            height: auto;
            position: static;

            .right {
                padding: 0 0.75rem;
                margin-top: 1.5rem;
                position: static;
                width: 100%;

                .title {
                    font-size: 2rem;
                    font-weight: 500;
                    line-height: 2.75rem; /* 137.5% */
                    letter-spacing: -0.008rem;
                    text-align: center;
                }

                .desc {
                    margin-top: 1.5rem;
                }

                .features {
                    margin-top: 1.5rem;
                    padding: 1rem;
                    gap: 1rem;

                    .feature {
                        .checked {
                            margin-top: 0.25rem;
                            width: 1rem;
                            height: 1rem;
                            background: url('@{image_root}/web/cgms/index/checked.svg') no-repeat center;
                            background-size: contain;
                        }

                        .desc {
                            margin-top: 0;
                            width: 18.4375rem;
                        }
                    }
                }
            }

            .img-container {
                width: 100%;
                position: relative;
                overflow: hidden;
                height: 29.29688rem;

                .img {
                    display: block;
                    width: 80rem;
                    height: 29.29688rem;
                    position: absolute;
                    top: 0;
                    left: -9rem;
                }
            }
        }
    }

    .packet-plans {
        height: auto;
        width: 100%;
        padding: 0;

        .content {
            width: 100%;
            margin: 0 auto;
            padding-bottom: 1.5rem;

            .title {
                padding: 1.5rem 0;
                font-size: 2rem;
                letter-spacing: -0.008rem;
            }

            .guide-contact {
                border-radius: 0.5rem;
                width: 21.9375rem;
                padding: 1rem 1rem;
                text-align: center;

                flex-direction: column;
                gap: 1rem;

                .guide {
                    
                    max-width: inherit;
                    
                    p{
                        padding: 0 0.5rem;
                        text-align: left;
                    }
                }

                .contact {
                    padding-right: 0;

                    a {
                        color: #0E0F10;
                    }
                }

                h3 {
                    font-size: 1.0625rem;
                    line-height: 1.5rem; /* 141.176% */
                    letter-spacing: -0.00425rem;
                }

                p {
                    margin-top: 0.5rem;
                    
                }
            }

            .plans {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;

                .plan {
                    border-radius: 0.5rem;
                    padding-top: 1.5rem;
                    width: 21.9375rem;
                    height: auto;

                   

                    .buy {
                        position: static;
                        margin-top: 3rem;
                        width: 100%;
                        


                        .price {
                            font-size: 1.5rem;
                            line-height: 2rem; /* 137.5% */
                        }

                        .gst {
                            font-size: 0.75rem;
                            letter-spacing: -0.003rem;
                        }

                        .buy-now {
                            padding: 0.5rem 0.935rem;
                            
                        }
                    }
                }
            }
        }
    }

    .doctor-registration {
        background-size: auto 100%;
        .doctor{
            display: none;
        }

        .content {
            width: fit-content;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 0.75rem;

            .title{
                font-size: 2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2.75rem; /* 137.5% */
                letter-spacing: -0.008rem;
                
                
            }
            .desc {
                width:fit-content;
            }
            .form{
                width: auto;
                .input-submit{
                    text-align: center;
                }
            }

            
        }
    }

    .manual_download {
        width: 100%;
        padding: 2.5rem 0.75rem;

        .title {
            text-align: center;
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2.75rem; /* 137.5% */
            letter-spacing: -0.008rem;
        }

        .product_list {
            flex-direction: column;
            width: 100%;
            
            .product {
                width: 21.9375rem;
                height: auto;

                h2{
                    text-align: center;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2rem; /* 133.333% */
                    letter-spacing: -0.006rem;
                }

                p{

                    img{
                        display: block;
                        height: 10rem;
                    }
                }

                .download_btn{
                    margin: 2.5rem auto 0;
                    width: 17rem;
                    padding: 0.75rem 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.5rem;
                    background: linear-gradient(180deg, #0081DE 0%, #0067B2 100%);
                    box-shadow: 0 -0.0625rem 0.0625rem 0 #000 inset, 0 0.0625rem 0.0625rem 0 rgba(255, 255, 255, 0.60) inset;

                   

                    cursor: pointer;
                }
            }
        }
    }

    .control_diabetes {
        width: 100%;
        padding: 1.5rem 0.75rem;
        text-align: center;

        .title {
            width: 100%;
            margin: 0 auto;
            font-size: 2rem;
            font-weight: 500;
            letter-spacing: -0.008rem;
        }

        .desc {
            width: 100%;
            margin: 1rem auto;
        }
    }


}